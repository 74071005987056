.heading-2:not(:first-child) {
  margin-top: 60px;
}

$progress-color: #bada55;

#nprogress .bar {
  background: $progress-color;
}

#nprogress .peg {
  box-shadow: 0 0 10px $progress-color, 0 0 5px $progress-color;
}

#nprogress .spinner-icon {
  border-top-color: $progress-color;
  border-left-color: $progress-color;
}

.recharts-surface {
  overflow: visible;
}

.react-tiny-popover-container {
  z-index: 100;
}
